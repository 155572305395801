import React from "react"
import { Link } from "gatsby"
import {
  Row,
  Col,
  Container,
  Breadcrumb
} from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"

import LuteinComplexMobile from "./../images/about-mobile.jpg"
import LuteinComplexTablet from "./../images/about-tablet.jpg"
import LuteinComplexDesktop from "./../images/about-desktop.jpg"
import LuteinComplexXL from "./../images/about-desktop-xl.jpg"
// import Feature1 from "./../images/eu-fund.svg"
import Feature2 from "./../images/vh-eu-fund.svg"


const AboutPage = () => (
  <Layout backButton={true} pageInfo={{ pageName: "about" }}>
    <Seo title="За Вита Херб" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <Breadcrumb.Item active>ЗА ВИТА ХЕРБ</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="About Vita Herb"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
      </Row>

      <Row className="mb-5 product-container">
        <Col sm={12} className="">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            За Вита Херб
          </h1>
        </Col>
        <Col sm={12}>
          <p>
            Създадохме Вита Херб, защото вярваме, че пътят към здравето минава
            през природата. Стартирахме през 2002 година като семейна фирма,
            произвеждаща билкови таблетки по изпитани рецепти, съдържащи само
            подбрани билки. След редица проучвания, през 2004 година, в
            сътрудничество с Капсугел – Франция разработихме и представихме на
            българския пазар и продукти, произведени по специалната патентована
            технология LICAPS™. Към днешна дата Вита Херб е един от водещите
            производители и вносители на хранителни добавки в България.
          </p>
          <p>Ние във Вита Херб залагаме на 4 основни принципа:</p>
          <ul>
            <li>
              Научна подкрепа – Формулите са създадени на базата на задълбочени
              литературни и научни проучвания.
            </li>
            <li>
              Близки отношения със здравни професионалисти – Партньорство с
              български и чуждестранни специалисти от различни сектори на
              медицината.
            </li>
            <li>
              Качествени продукти – Ефективносттa, чистотата и безопасността на
              продуктите са основният приоритет на бранда.
            </li>
            <li>Разбиране и стремеж към задоволяване нуждите на клиентите.</li>
          </ul>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Ангажимент към качеството</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col sm={12} className="order-md-0">
          <p>
            Ефективността на нашите продукти и тяхната пълна безопасност са
            осигурени чрез спазване на подходяща дозировка, гарантирща опитмална
            биоактивност и поддържащи клинични доказателства.
          </p>
          <p>
            Нашите иновативни и изключителни (уникални) формули са разработени
            от естествени сътавки, които отговарят на изискванията на новите
            разпоредби за хранителните добавки (Европейска Директива
            2006/46/EC).
          </p>
          <p>
            Ние работим със сертифицирани френски лаборатории при производството
            на нашите продукти, които следват добра фармацевтична производствена
            практика и отговарят на строгите стандарти за хигиена и безопасност.
            Редовните инспекции по време на производствения процес гарантират на
            потребителя виското качество и пълната проследимост на продукта.
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={{ span: 7, offset: 3 }} className="">
          <img srcSet={Feature2} alt="" className="mw-100 mx-auto" />
        </Col>
      </Row>
      {/* <Row className="product-container mb-5">
        <Col md={{ span: 7, offset: 3 }} className="">
          <img srcSet={Feature1} alt="" className="mw-100 mx-auto" />
        </Col>
      </Row> */}
    </Container>
  </Layout>
)

export default AboutPage
